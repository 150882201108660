import React from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"
import { toUrl } from "../utils"
import cornerPaper from "Assets/popup/box/Popbox_nocross.png"
import button from "Assets/popup/box/Check_box.png"
import antibacterial from "Assets/popup/objects/antibacterial.png"
import cummings from "Assets/popup/objects/cummings.png"
import bubble from "Assets/popup/objects/bubble.png"
import handswash from "Assets/popup/objects/handswash.png"
import hour_outside from "Assets/popup/objects/hour_outside.png"
import iu from "Assets/popup/objects/iu.png"
import mask from "Assets/popup/objects/mask.png"
import phone from "Assets/popup/objects/phone.png"
import test from "Assets/popup/objects/test.png"
import socialdistance from "Assets/popup/objects/socialdistance.png"
import bleach from "Assets/popup/objects/bleach.png"
import toiletpaper from "Assets/popup/objects/toiletpaper.png"
import walk from "Assets/popup/objects/walk.png"
import zoom from "Assets/popup/objects/zoom.png"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: 500,
    height: 200,
    marginRight: "-50%",
    marginTop: 0,
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF00",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    border: "none",
    backgroundImage: toUrl(cornerPaper),
    overflow: "visible",
    zIndex: 99999,
    paddingLeft: 30,
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.0)",
    zIndex: 99999,
  },
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root")

export default function Popup({
  popup,
  move,
  setOpenItem,
  increaseSanity,
  increaseRisk,
  setIsInside,
}) {
  //   const [popup, setPopup] = React.useState("bleach")
  const popupContent = popups[popup] || {}
  React.useEffect(() => {
    if (popup) {
      setIsOpen(true)
    }
  }, [popup])

  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [hasSetTrigger, setHasSetTrigger] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  //   const triggerPopup = (popupName) => {
  //     setPopup(popupName)
  //     setIsOpen(true)
  //   }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        // onRequestClose={closeModal}
        // className="s"
        style={customStyles}
        contentLabel="Example Modal"
      >
        <>
          <div
            className=""
            style={{ fontSize: "1.3em", width: 330, marginTop: 35 }}
          >
            {popupContent.text}
          </div>
          <div style={{ position: "absolute", bottom: 50 }}>
            <PopupButton
              onClick={() => {
                if (popupContent.move_to_room) {
                  move(popupContent.move_to_room)
                }
                if (popupContent.move_to_object) {
                  setOpenItem(popupContent.move_to_object)
                }
                increaseSanity(popupContent.sanity)
                increaseRisk(popupContent.risk)

                if (popupContent.set_is_inside) {
                  setIsInside(true)
                }
                if (popupContent.set_is_outside) {
                  setIsInside(false)
                }

                closeModal()
              }}
            >
              {popupContent.yestext || "Yes"}
            </PopupButton>
            <PopupButton onClick={closeModal}>
              {popupContent.notext || "No"}
            </PopupButton>
          </div>
          <img
            style={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
            src={popupContent.image}
          ></img>
          {/* <div
            onClick={() => setIsOpen(false)}
            className="backButtonContainer"
            style={{
              backgroundImage: toUrl(redPaper),
            }}
          >
            <img className="backButton" src={back} />
          </div> */}
        </>
      </Modal>
    </>
  )
}

const PopupButton = ({ children, onClick }) => {
  return (
    <div
      style={{
        backgroundImage: toUrl(button),
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        height: 55,
        width: 143,
        fontSize: "1.2em",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 20,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

const popups = {
  zoom: {
    text: "Take a family zoom call?",
    image: zoom,
    move_to_room: "lounge",
    move_to_object: "laptop",
    set_is_inside: true,
    sanity: 1,
    risk: 0,
  },
  instagram: {
    text: "Play on instagram?",
    image: iu,
    move_to_room: "lounge",
    move_to_object: "phone",
    set_is_inside: true,
    sanity: -1,
    risk: 0,
  },
  cummings: {
    text: "Sorry, this journey is illegal unless you're Dominic Cummings?",
    image: cummings,
    // move_to_room: "bedroom",
    // move_to_object: "phone",
    sanity: -1,
    risk: 0,
    yestext: "I am",
    notext: "I am not",
  },
  hour_outside: {
    text: "Take your one hour outside?",
    image: hour_outside,
    set_is_outside: true,
    move_to_room: "park",
    // move_to_object: "phone",
    sanity: 1,
    risk: 0,
  },
  toiletpaper: {
    text: "Bulk buy loo roll?",
    image: toiletpaper,
    // move_to_room: "park",
    // move_to_object: "phone",
    sanity: -1,
    risk: 1,
  },
  handgel: {
    text: "Use hand gel?",
    image: antibacterial,
    // move_to_room: "park",
    // move_to_object: "phone",
    sanity: -1,
    risk: 1,
  },
  mask: {
    text: "Do you have your mask?",
    image: mask,
    // move_to_room: "park",
    // move_to_object: "phone",
    sanity: -1,
    risk: -1,
  },
  bleach: {
    text:
      "Do you want an injection of bleach to cure covid!? Donald Trump thinks it’s a great idea!",
    image: bleach,
    // move_to_room: "park",
    // move_to_object: "phone",
    sanity: -5,
    risk: 1,
  },
  handswash: {
    text: "Wash your hands for 20 seconds before you touch anything!",
    image: handswash,
    // move_to_room: "park",
    // move_to_object: "phone",
    sanity: -1,
    risk: -2,
    yestext: "OK",
    notext: "I won't",
  },
  speak_to_someone: {
    text: "Are you okay, do you need to speak to someone?",
    image: phone,
    // move_to_room: "park",
    // move_to_object: "phone",
    sanity: 5,
    risk: 0,
    yestext: "Yes",
    notext: "No",
  },
  test: {
    text: "You're at high risk, take a test?",
    image: test,
    // move_to_room: "park",
    // move_to_object: "phone",
    sanity: 0,
    risk: -5,
    yestext: "Yes",
    notext: "No",
  },
}
