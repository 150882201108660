import React from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"
import ObjectContents from "../Content/ObjectContents"
import { toUrl } from "../utils"
import links from "Assets/misc/links.png"
import linksPopover from "Assets/misc/links-popover.png"

const customStyles = {
  content: {
    top: "30%",
    left: "40%",
    // right: "auto",
    // bottom: "auto",
    background: toUrl(linksPopover),
    width: 250,
    height: 450,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.0)",
  },
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root")

export default function Links({ text }) {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        // className="s"
        style={customStyles}
        contentLabel="Example Modal"
        onClick={closeModal}
      >
        <div className="link-exit" onClick={closeModal}></div>
        <div
          className="link-content-overlay"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Modal>

      <img src={links} onClick={openModal} className="links" />
    </>
  )
}
