import React, { useState } from "react"

import Scene from "./Scenes/Scene"
import sceneList from "./Scenes/sceneList"
import "./App.css"
import Rainbow from "./ui/Rainbow"
import Chili from "./ui/Chili"
import Welcome from "./Welcome/Welcome"

function App() {
  const [entryTime, setEntryTime] = useState(new Date())
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true)
  const [room, setRoom] = useState("lounge")
  const [sanity, setSanity] = useState(4)
  const [risk, setRisk] = useState(0)
  const randomize = (x) => {
    let zero = Math.random() > 0.9 ? 0 : 1
    let negative = Math.random() > 0.5 ? -1 : 1
    return x * zero * negative
  }
  const dontRandomize = (x) => x // Just for triggering randomize on and off
  const withinBounds = (x) => Math.max(Math.min(x, 4), 0)
  const increaseSanity = (inc) =>
    setSanity(withinBounds(sanity + dontRandomize(inc)))
  const increaseRisk = (inc) => setRisk(withinBounds(risk + dontRandomize(inc)))
  let scene = sceneList[room]
  scene = { ...scene, name: room }

  if (showWelcomeScreen)
    return <Welcome enter={() => setShowWelcomeScreen(false)}></Welcome>

  return (
    <div className="App active">
      <header className="App-header">
        <Scene
          scene={scene}
          changeRoom={setRoom}
          increaseSanity={increaseSanity}
          increaseRisk={increaseRisk}
          risk={risk}
          sanity={sanity}
          entryTime={entryTime}
          returnToWelcome={() => {
            setShowWelcomeScreen(true)
            setEntryTime(new Date())
          }}
        >
          <Chili value={risk}></Chili>
          <Rainbow value={sanity}></Rainbow>
        </Scene>
      </header>
    </div>
  )
}

export default App
