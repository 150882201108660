const contentList2 = {
  "2metersign": [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }, { url: "photo_2.jpg" }],
    },
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "photo_1.JPG",
      media: [{ url: "photo_1.JPG" }, { url: "photo_2.JPG" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "logo192.png",
      media: [{ url: "photo_1.jpg" }, { url: "photo_2.jpg" }],
    },
  ],
}

const contentList = {
  "2metersign": [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }, { url: "photo_2.jpg" }],
    },
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "photo_1.JPG",
      media: [{ url: "photo_1.JPG" }, { url: "photo_2.JPG" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "18 March 2020 – “Public Health Messaging” – “Wash your hands more often for 20 seconds” was the initial coronavirus campaign rolled out by the Government and the NHS across the transport network, media and beyond. I think to this day it’s still one of the most vital messages with regards to mitigating transmission." }],
    },
  ],
  azmap: [
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [     { content: "<b>Walking Routes</b><br><br>\
      General note: During the core lockdown (before anything was open) my main pastime was going for walks in the local area. I tended to do two walks a day, at least one in the company of my partner. <br><br> These walks were uplifting and relaxing, especially since spring weather proved to be dry and sunny. I enjoyed people-watching, observing different types of buildings and streets, and appreciating all the natural beauty in the area (including different parks, the riverside, various creeks). I noticed, for example, much more wildlife presence, such as birds and baby ducks.<br><br> Following are some explanations of some of my most common routes, which accompany the Google Maps screenshots.<br><br>\
     "},
        { url: "photo_1.jpg", caption: "Walk 1 – The High Street - This was a walk I sometimes did several times a day because it’s my immediate street. I would often visit several shops in one go, particularly Wellbeloved Butchers (not quite on the high street, but on the other side of the street, on the south end), ASDA, Tony’s Daily and Iceland." },
        { url: "photo_2.jpg", caption: "Walk 2  – Surrey Quays and South Bermondsey This walk is less scenic but includes a pedestrian path connecting Surrey Quays with Surrey Canal Road. It also goes past Folkestone Gardens which has been a highlight of lockdown. Lewisham Council kept all parks, basketball courts, skate parks, outdoor gyms and playgrounds open during lockdown, which was a blessing. I really enjoyed watching local kids do tricks at the Folkestone Skate Park." },
        { url: "photo_3.jpg", caption: "Walk 3 – Peckham - Peckham is similar to Deptford in that it’s a hub comprising a mix of communities and a wide variety of shops. I enjoyed walking there and checking out the vibe. I was especially pleased with a food truck called Salas which sells wraps made with Algerian flat bread reopened." },
        { url: "photo_4.jpg", caption: "Walk 4 – Greenwich Riverside: Probably the walk I did the most. The riverside is scenic, calming, picturesque… I love seeing it at different times of day (but especially sunset). Throughout lockdown it was very lively, full of people hanging out on benches, families playing, runners and cyclists… It was refreshing to see." },
        { url: "photo_5.jpg", caption: "Walk 5 – Ladywell + Brockley: This walk incorporates some nice parks such as Hilly Fields, Brockley Cemetery and Ladywell Fields. Brockley in particular was one of the hubs which had the most food & drink businesses open during lockdown, which was nice. My favourite place to go to was Sundae, an ice cream shop that offers amazing soft-serve and gelato." },
        { url: "photo_6.JPG", caption: "Walk 6 – Isle of Dogs: This walk crossed into the Isle of Dogs through the Greenwich Foot Tunnel and then explored the area around Island Gardens, Mudchute, the east riverside and parts of Canary Wharf. This area looked so tranquil during lockdown, without the rush of office workers. I loved looking at the skyscrapers reflected over the water basins." },
        { url: "photo_7.JPG", caption: "Walk 7 – Lewisham: Another common route, though not a very scenic one. I sometimes would stop off at shops like Boots and Savers at Lewisham to restock on toiletries." },
      ],
    },
  ],
  bed: [
    {
      name: "sarah",
      slug: "sarah",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "video_1.mp4", caption: "This short video piece is focused on insomnia during lockdown. The close up of the bed bars filmed throughout the clip, visualize the dullness and confining feeling of not being able to sleep at night. The use of overlapping radios reporting news of Covid-19 are meant to create a chaotic, stress inducing reaction. The snippets of hands being washed, and gloves being put on over the bed bars, represent the rapid, intrusive thoughts lockdown induced insomnia can create. The distant sirens and heavy breathing at the end are a final quieter reminder of the pandemic going on around us." }
      ]
    },
  ],
  bike: [
    {
      name: "megan",
      slug: "megan",
      thumbnailUrl: "photo_1.jpeg",
      media: [{ url: "photo_1.jpeg" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "24 March 2020 – “My New Bike” – On the 23rd, what I suspected was announced: a full lockdown. That evening I went on Gumtree and answered a local ad about a bike for sale at the very affordable price of £80. The next day, I met the seller at Deptford Park and collected my purchase. It would become a real lifesaver for me in terms of getting exercise and minimising my reliance on public transport. Best of all, traffic was minimal for those initial months of lockdown, which made cycling feel like a treat." }],
    },
    {
      name: "tarif",
      slug: "tarif",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }, { url: "video_2.mp4" }],
    },
  ],
  birthdaycard: [
    {
      name: "annmarie",
      slug: "annmarie",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }, { url: "photo_2.jpg" }],
    },
  ],
  car: [
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }, { url: "video_2.mp4" }],
    },
  ],
  collageposter: [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg" },
        { url: "photo_2.jpg" },
        { url: "photo_3.jpg" },
      ],
    },
    {
      name: "johanna",
      slug: "johanna",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "For one week our living room became a den" }],
    },
    {
      name: "megan",
      slug: "megan",
      thumbnailUrl: "photo_1.jpeg",
      media: [
        { url: "Art.mp4" },
        // { url: "photo_1.jpeg" },
        // { url: "photo_2.jpeg" },
        // { url: "photo_3.jpeg" },
        // { url: "photo_4.jpeg" },
        // { url: "photo_5.jpeg" },
        // { url: "photo_6.jpeg" },
        // { url: "photo_7.jpeg" },
      ],
    },
    {
      name: "olga",
      slug: "olga",
      thumbnailUrl: "photo_1.JPG",
      media: [
        { url: "photo_1.JPG", caption: "I took some time to learn an ancient weaving technique called Sprang. " },
        { url: "photo_2.JPG", caption: "I have still been able to access and use my studio space in Deptford during lockdown. It has been a really good time for me to focus on painting." },
        { url: "photo_3.JPG", caption: "I have been crocheting necklaces, I tried dying this one with avocado pips." },
      ],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl:
        "image_1.JPG",
      media: [
        { url: "image_1.JPG" },
        { url: "image_10.JPG" },
        { url: "image_11.JPG" },
        { url: "image_12.JPG" },
        { url: "image_13.JPG" },
        { url: "image_2.JPG" },
        { url: "image_3.JPG" },
        { url: "image_4.JPG" },
        { url: "image_5.JPG" },
        { url: "image_6.JPG" },
        { url: "image_7.JPG" },
        { url: "image_8.JPG" },
        { url: "image_9.JPG" },
      ],
    },
  ],
  crossing: [

    {
      name: "sarah",
      slug: "sarah",
      thumbnailUrl: "photo_1.JPG",
      media: [
        { url: "photo_1.JPG" },
        { url: "photo_2.JPG" },
        { url: "photo_3.JPG" },
        { url: "photo_4.JPG" },
        { url: "photo_5.JPG" },
        { url: "photo_6.JPG" },
        { url: "photo_7.JPG" },
        { url: "photo_8.JPG" },
        { url: "video_1.mp4" },
        { url: "video_2.mp4" },
        { url: "video_3.mp4" },
        { url: "video_4.mp4" },
        { url: "video_5.mp4" },
        { url: "video_6.mp4" },
        { url: "video_7.mp4" },
      ],
    },
  ],
  cupboard: [
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "photo_1.JPG",
      media: [{ url: "photo_1.JPG" }],
    },
    {
      name: "olga",
      slug: "olga",
      thumbnailUrl: "photo_1.JPG",
      media: [{ url: "photo_1.JPG", caption: "I ordered PPE online as I will be going back to working as a support worker on the 1st of June." }],
    },
  ],
  desk: [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg" },
        { url: "photo_2.jpg" },
        { url: "photo_3.jpg" },
      ],
    },
    {
      name: "johanna",
      slug: "johanna",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg", caption: "Listening to a lecture on my masters course at home as all teaching moved to online. " },
        { url: "photo_2.jpg", caption: "Working parent's reality " },
        { url: "photo_3.jpg", caption: "My daughter has always been interested to see me at work so this was a great time to show her and let her try it out too" },
        { url: "photo_4.jpg", caption: "Homework set up in my bedroom. I need assitive equipment due to my disability so I brought some from work and some I already had at home." },
      ],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg", caption: "18 March 2020 – “ES Cover: ‘Heart of London is Virus Hotspot’” – I spotted this cover which made it seem like inner London was a very dangerous place to be as the pandemic unfolded. I remember my parents in Spain asking if things were getting bad here and certain friends of mine telling me in text or video conversations that they felt fearful and panicked. Yet I felt relatively calm—my mindset was one of wanting to live without fear or anxiety as much as possible." },
        { url: "photo_2.jpg", caption: "19 March 2020 – “ES Cover: ‘No Lockdown of London, Says Boris’” – I felt relieved that perhaps a full lockdown wouldn’t be mandated, but from what I kept hearing from certain friends and reading online (on Twitter and Reddit mostly), I suspected that Boris would be pressured into implementing stricter rules and that we were merely being eased into it." },
        { url: "photo_3.jpg", caption: "25 March 2020 – “ES Cover: ‘Prince Charles Has the Virus’” – In the UK, Prince Charles was the first major public figure to be infected, and Boris Johnson followed a week later. It was exactly this week too that my mum told me she had been infected, and my partner presented symptoms. It made the pandemic feel much more real but it was also weirdly comforting to know that many public figures were also going through it." },
        { url: "photo_4.jpg", caption: "6 April 2020 – “Summer in the Spring” – This weather snapshot from the Evening Standard brightened up my day because it felt like summer was coming early. I genuinely feel like I would not have survived lockdown so easily if we hadn’t had exceptionally mild and sunny weather!" },
        { url: "photo_5.jpg", caption: "22 May 2020 – “Metro Cover: Virus Cases Plummet in Capital” – It was nice to see the media reporting positive covid news for once." },
      ],
    },
    {
      name: "olga",
      slug: "olga",
      thumbnailUrl: "photo_1.JPG",
      media: [{ url: "photo_1.JPG", caption: "I have been spending a lot of time at my desk infront of the computer. Looking at the news, sorting out my economic situation, chatting to family, doing therapy, taking online courses in various subjects, looking for new jobs etc. " }],
    },
    {
      name: "tarif",
      slug: "tarif",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }],
    },
  ],
  diary: [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }],
    },

    {
      name: "katie",
      slug: "katie",
      thumbnailUrl:
        "notes.png",
      media: [{ content: "<b>Life under lockdown</b><br>\
      <br>\
      There's nothing like a pandemic to turn your life upside down. <br>\
      <br>\
      On 15th March we were celebrating my brother in laws 40th birthday with 40 friends and family.  The following day I learnt that at 33 weeks pregnant I was now a vulnerable person and was advised to socially isolate for 12 weeks.  <br>\
      <br>\
      With a 19 month toddler life unto this point was full and hectic. Swimming,  gymnastics,  clubs,  meet up with friends; our days were busy. The idea of being at home  having no access to play areas and outside spaces, whilst living in a flat with no garden, initially filled me with panic. The idea of being in was suffocating and I worried about how i’d cope with a more fluid schedule and how my little one would manage having such a change in life.  <br>\
      <br>\
      The reality of the lockdown however has been surprising.  joy and wonderment has been found in the smallest,  strangest and most normal places.  Rocks have become mountains to climb, sand patches have become beaches,   farms and zoos were replaced by finding ducks, herons, ladybirds,  ants and snails. Refuse collections have at points been the only focus of our day and the fascination of watching the bins be emptied and the excitement of refuse collectors waving at us have equalled any play or cinema experience we've had.  Finding diggers or watching trains has provided hours of fun and conversation. All these things which we would pay no attention to before suddenly have become the focus of our days. The joy to be found in nature and noticing the everyday humdrum has given its both a new appreciation of life on our doorstep.  <br>\
      <br>\
      As lockdown came, the outdoors, which before had been hectic and busy, provided our tranquillity and time inside the flat was our chaos. Neighbours became close friends and provided endless entertainment from reading their books to my toddler on the communal series,  lending us their plants to water or garden to play in or  creating fabulous play opportunities out of cardboard boxes.  Others cycled into the city to track down the right size nappies or  performed concerts out of their window with us being their audience stood at least 2 metres apart.  All were on standby to provide childcare in the event of me going into labour. <br>\
      <br>\
      As neighbours rallied round,  family mourned the time they were missing out on. Not to be able to visit the new baby,  hug us or fill our freezer ready for the new arrival.  Being up north has never felt as far away. Zoom and whatsapp calls became the norm and became increasingly entertaining; a toddler watching a dog have a bath is not something that would have been thought of before lockdown!<br>\
      <br>\
      So as lockdown comes to an end we look forwards to seeing our friends and family, to introduce our son to the world and to see our daughter play with her friends.  We look forwards to buying  bread from the bakery, eating out and getting a massage to sooth the aches of working from home.  Yet we hope beyond everything else that we can keep the magic of discovering the humdrum of life and that the comradery of those most local to us doesn't dissipate once we can stand closer than 2 meters apart  <br>\
      "}],
    },
    {
      name: "megan",
      slug: "megan",
      thumbnailUrl: "photo_1.jpeg",
      media: [{ url: "Intro.mp4" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "notes.png",
      media: [{ content: "<b>Collection of Photos Documenting the Deptford Community and Surrounding Areas during 4 months of lockdown</b><br><br>\
      General note: mid-March, lockdown began. First we had one week of “soft” lockdown and then the Government ordered a full-on shutdown and released the mandate to “stay home save lives”. I had already been working from home since January as I had taken up a contract with a company not based in London, so I knew my daily routine would not be hugely affected by a shutdown compared to other people. However, I worried about the impact on society at large, becoming acutely aware that many people in the local area might lose their sources of income.<br><br>\
      I resolved to continue to spend money locally and support businesses where I could. I also resolved to spend ample time outdoors, exploring local areas and keeping my mental health in check. In addition to daily walks, I kept up my fitness by going for weekly runs (which I already did pre-lockdown) and by incorporating cycle rides (after buying a bike at the start of lockdown). <br><br>\
      I was in frequent contact with my parents in Spain, who were under one of the strictest lockdowns in Europe, virtually confined to their apartment for weeks. I felt very fortunate to be in the UK, where the government respected personal liberties and set out guidelines for our daily lives rather than strictly enforced measures. My awareness of how draconian the situation was in Spain made me more determined to enjoy the outdoors during lockdown.<br><br>\
      The virus itself also impacted my life. In mid-March, my mother contracted it (in Spain—confirmed by diagnostic test) and so did my partner, whom I lived with (she couldn’t confirm it by test, but she felt fatigue and lost her sense of smell completely, which we found out is a key symptom). For a few days, I felt quite anxious. Firstly, I worried about my mother who had bad fever and felt very weak (she is 71). Then I worried that my dad would get it, since her was caring for her, or that I would get it, give that my partner had it.<br><br>\
      I did a lot of internet research during that time and my anxiety dissipated. I realised that as someone with no underlying conditions, my mother would almost certainly be fine (and she was). My partner, meanwhile, never developed any of the bad symptoms like flu or breathing issues. She started to feel fine after a week (although her smell didn’t come back 100% for several). My dad and I never got infected that we know. <br><br>\
      This experience made me suspect that the virus had already spread widely in London and Madrid by mid-March, and many of us had probably been exposed. This made me feel confident that it was not something to feel gripped in fear by, and I hoped that lockdown wouldn’t go on indefinitely. I questioned the worst predictions made in the press, which seemed designed to generate fear and hysteria.<br><br>\
      Lockdown was a harmonious period for me in some ways, as I got into simple routines and grew to appreciate basic pleasures (like taking walks, observing urban landscapes, listening to music, and cooking). Yet it also ended up being very disruptive at a very personal level: in late June, my partner and I started to have serious chats about the future and ended up coming to the conclusion that we should split up. We had been together for over 12 years—cohabiting for 10 of those years—so it was not a light or easy decision.<br><br>\
      However, it was a mutual and amicable decision borne out of a lot of introspection and reflection which I don’t think would have happened without lockdown. In retrospect, lockdown was also the perfect epilogue to our relationship—a chance to strip everything back and enjoy companionship before parting ways.<br><br>\
      (A catalyst for the relationship ending was that lockdown also provided me with the chance to invest more quality time in a secondary relationship that I had started pursuing a year earlier after my partner and I decided to open up. This secondary relationship involved me leaving the Deptford area once a week to stay overnight in southwest London. I have decided not to document this aspect of lockdown given that it does not relate to my local community.)<br><br>\
      " }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [{ url: "photo_1.JPG" }, { url: "video_1.mp4" }],
    },
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }, {content: "At first it felt like there was optimism and that we could change after all this. Change how we value things in society, what and who we value. But the optimism seemed to drain away once we started to lose trust in all the decisions the government was making, and that all seemed to happen at the same time as Dominic Cummings broke the lockdown rules. And he kept his job. Since then it all feels chaotic.  (with photos of rainbows etc)<br>\
      <br>\
     There is so much energy involved in trying to decide if what we are doing is ok all the time. Is it ok to go to the shop again, which shop has good social distancing, is my friend standing too close to me, should I say something or will she be offended; can I hug my Dad. After the older woman with the dog stepped into the road to avoid me I felt guilty for the rest of the day. (with photos of markings on the floor)<br>\
      <br>\
     At the beginning of the lockdown I slept better than I have done in years.  It was so peaceful without the drone of cars passing my window, and I had such vivid dreams. I had a sense of space around me, as if there was time to take my time with everything. (window photo)<br>\
      <br>\
     One sunny day during the lockdown we went down to Waterloo Bridge and walked on the stony beach next to the Thames. It was so quiet. We tried and failed to skim stones on the water and picked up some shells. Ingrid took hers home to paint. There were only a couple of other people on the beach and we all said hello.  (shell photo)<br>\
      <br>\
     How long do I have to keep on washing my shopping? Becky says she sprays everything with bleach. Claudia just makes sure she washes her hands. We use fairy liquid. Every time I do it I feeling ridiculous, as if this whole virus is made up. Does this really help? I have no idea. (with new video clip)<br>\
      <br>\
     After nearly three months, my daughter's tutor called me for the first time and he told me she hadn't handed in any work at all. Nothing since lockdown. This was the first time I knew about it because all the emails from school go straight to my daughter and she won't let me see them. I just cried.  (photo?)<br>\
      <br>\
     My home situation is ok. We've still got our jobs and we've got a laptop each and outside space. I made the film to find out how other people living around me felt about the lockdown. I interviewed a campaigner and my friend's neighbour, and a dad from school. When I explained the project to them, I said that to me class meant people who are living in different situations. How does lockdown impact differently on people who don't have a garden, who are living in insecure housing, who struggle with anxieties. It helped me to have these structured conversations, because I do this as part of my job. It felt like the right thing to do, to reach out in this way and I saw my own preoccupations mirrored in other people's experiences even though our situations are all very different. (the short film)<br>\
     <br>\
     "}],
    },
  ],
  door: [
    {
      name: "annmarie",
      slug: "annmarie",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "photo_1.JPG",
      media: [{ url: "photo_1.JPG" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "16 March 2020 – “Hand sanitiser at the ready” – Despite shortages of hand sanitiser, I found some in a drawer at home and started to carry it around. I felt reassured that as long as I adopted stricter hygiene measures, there was no reason to feel anxious about the virus." }],
    },
  ],
  egg: [
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }, { url: "video_2.mp4" }],
    },
  ],
  familyportrait: [
    {
      name: "megan",
      slug: "megan",
      thumbnailUrl: "photo_1.jpeg",
      media: [
        { url: "Family.mp4" }
        // { url: "photo_1.jpeg" },
        // { url: "photo_2.jpeg" },
        // { url: "photo_3.jpeg" },
      ],
    },
    {
      name: "tarif",
      slug: "tarif",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }],
    },
  ],
  fridge: [
    {
      name: "annmarie",
      slug: "annmarie",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_0.jpg",
      media: [
        { content: "<b>Collection of Photos Documenting Lockdown Meals</b><br><br>\
        General note: Grocery shopping and popping out to local food shops became a daily ritual during lockdown. In turn, this led to a more creative approach to cooking (as well as greater indulgence in snack foods). I started to splurge on new ingredients and quality produce, especially meat and seafood, in part because I was no longer eating out (which I previously did several times a week) but also because I had more time in the evenings. I bought meat products at the iconic family-run Deptford butcher’s Wellbeloved and I bought seafood at the Waitrose by the Greenwich riverside. Eggs and bread I tended to buy at Tony’s Daily (small family-run food shop on the high street), while other basics I bought mostly at Iceland or ASDA, and occasionally at Tesco Express or Sainsbury’s Local. <br><br>\
        For the first time in my life, my approach to cooking went beyond seeing what I could throw together in a quick, functional way. Instead, I started to take pride in testing out new dishes and cooking became something I looked forward to. The meals I prepared were still fairly simple, but more varied than what I would have cooked pre-lockdown. My partner was less inclined to experiment, so I ended up cooking our shared meals 95% of the time, while she did the washing-up. <br><br>\
        One thing that struck me is that cooking nice meals relaxed me and provided me with a sense of accomplishment. It also made me feel healthier. <br><br>\
        "},
        { url: "photo_0.jpg" },
        { url: "photo_7.jpg", g: true, caption: "28 May 2020 – “Salmon with Prawns, Vegetables and Rice” – Salmon and prawns from Waitrose. The rice is a special type of Spanish rice which I bought at Ayres Bakery in Nunhead." },
        { url: "photo_8.jpg", g: true, caption: "4 May 2020 – “Beef Stew” – Using beef from Wellbeloved and a recipe I looked up online." },
        { url: "photo_9.jpg", g: true, caption: "31 May 2020 – “Pancakes and Bacon” – Bacon from Wellbeloved and pancakes made with pancake mix from Blackbird Bakery in Peckham." },
        { url: "photo_10.jpg", g: true, caption: "7 June 2020 – “Chicken, Butternut Squash and Rice” – Chicken breast from Wellbeloved and Thai rice from the Everything Chinese Supermarket in Deptford." },
        { url: "photo_11.jpg", g: true, caption: "9 June 2020 – “Hake with Vegetables and Rice” – I cooked the hake using a video tutorial my mother sent me. The rice is Spanish rice from Ayres Bakery in Peckham and the hake is from the Waitrose seafood counter." },
        { url: "photo_1.jpg", g: true, caption: "17 June 2020 – “Scrambled Eggs, Croquettes and Avocado” – Bread and eggs from Tony’s Daily, avocado from Deptford Market fruit traders, and croquettes from The Tapas Room, a local restaurant in Deptford Market Yard which sold take-away ingredients and food throughout lockdown." },
        { url: "photo_2.jpg", g: true, caption: "1 May 2020 – “Tuna Steak with Green Beans, Aubergine and Cous Cous” – Using tuna steaks from Waitrose." },
        { url: "photo_3.jpg", g: true, caption: "29 May 2020 – “Beef Stew” – I put a twist on this batch of beef stew: I used gnocchi from La Tua instead of potatoes, which worked quite well." },
        { url: "photo_4.jpg", g: true, caption: "11 May 2020 – “Monkfish with Vegetables and Cous Cous” – Using monkfish from the seafood counter at Waitrose. Monkfish is quite expensive and I would never have bought it pre-lockdown." },
        { url: "photo_5.jpg", g: true, caption: "20 May 2020 – “Garlic Prawns” – From the Waitrose seafood counter." },
        { url: "photo_6.jpg", g: true, caption: "20 May 2020 – “Seafood Linguine” – I always buy fresh pasta from La Tua in Borough Market and I was sad when they shut during March and April. However, by May they had reopened. As well as garlic prawns, I put scallops in the dish, which I bought at Waitrose. I normally would not have bought scallops pre-lockdown." },
      ],
    },
    {
      name: "olga",
      slug: "olga",
      thumbnailUrl: "photo_1.JPG",
      media: [{ url: "photo_1.JPG", caption: "During lockdown I have been keeping extra stores of dried foods like lentils and beans which is not something I have ever done before." }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "photo_1.JPG",
      media: [{ url: "photo_1.JPG" }],
    },
    {
      name: "tarif",
      slug: "tarif",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }],
    },
  ],
  gardenchair: [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "22 April 2020 – “Working on the Balcony” – Normally my partner and I wouldn’t make much use of the balcony, because air pollution means it’s always really dirty and the weather can be erratic, plus there’s nicer places to sit (like parks or benches). But given that lockdown awarded us extra time, we cleaned the balcony and I tested out doing some work on my laptop while sitting on a stool and using boxes as a table. It was ok, but I only did it once because it wasn’t that comfortable!" }],
    },
    {
      name: "olga",
      slug: "olga",
      thumbnailUrl: "photo_1.JPG",
      media: [{ url: "photo_1.JPG", caption: "The house I live in has a garden which has been really good. I drink coffee here in the afternoon. " }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }],
    },
  ],
  gardenfork: [
    {
      name: "annmarie",
      slug: "annmarie",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }],
    },
    {
      name: "johanna",
      slug: "johanna",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "video_1.mp4" },
        { url: "photo_1.jpeg", caption: "Unbelievably when the lockdown began it was still so cold outside! Getting some fresh air" },
        { url: "photo_2.jpg", caption: "As we couldn't go outside much we started to look at what we had available and made the most out of that. We don't usually use the balcony much as it is towards the busy high street so it's noisy and dirty out there but as the roads became more quiet, it became more attractive. " }],
    },
    {
      name: "megan",
      slug: "megan",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "Basics.mp4" },
        { url: "photo_1.jpeg" },
        { url: "photo_2.jpeg" },
        // { url: "video_1.mp4" },
        // { url: "video_2.mp4" },
      ],
    },
    {
      name: "tarif",
      slug: "tarif",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg" },
        { url: "photo_2.jpg" },
        { url: "photo_3.jpg" },
        { url: "photo_4.jpg" },
      ],
    },
  ],
  growingboxes: [
    {
      name: "aga",
      slug: "aga",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg" },
        { url: "photo_2.jpg", caption: "‘Door of opportunity’ – In the beginning of the summer the residents of Farleigh Road decided to go forward with the annual street lunch party that was approved by council applying social distancing guidance. We gathered with young people around a local abandoned community center that by the end of the day was transformed by children’s creativity. They set up a bric-a-brac stall, art expo, pom pom workshop and street library. This event advocated for a ‘door of opportunity’ so young people can have access to facilities with fun and hands-on learning." },
        { url: "photo_3.jpg" },
        { url: "photo_4.jpg" },
        { url: "photo_5.jpg" },
        { url: "photo_6.jpg" },
        { url: "photo_7.jpg", caption: "The Magic Garden – Long before the coronavirus pandemic was announced, the residents of Nightingale Estate took the initiative to set up the community garden on the area left after demolished blocks, shops and youth center. Although the virus outburst we were happy that Nightingale Community Garden was opened and shortly it became the place of pilgrimage for many. By appointment anyone could get involved in their own way, some were making bed plants or mixing the soil when others were into discovering the beauty and taste of freshly grown fruits and veggies. Tenants’ collaborations initiated around the garden activities bore many fruits like The Nightingale Festival of Ideas that invited locals to share thoughts on how to help create a safe and productive community. https://festival.mybrightidea.net/index.html" },
        { url: "photo_8.jpg" },
        { url: "photo_9.jpg" },
      ],
    },
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
  ],
  guitar: [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "video_1.mp4" },
        { url: "video_2.mp4" },
        { url: "video_3.mp4" },
        { url: "video_4.mp4" },
        { url: "video_5.mp4" },
      ],
    },
  ],
  kidsplayground: [
    {
      name: "katie",
      slug: "katie",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "Empty parks. Empty places. Seeing the perplexed look on my daughters face and her trying to figure out where people were was familiar during lockdown." }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }, { url: "photo_2.jpg" }],
    },
  ],
  kidsplayhouse: [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "katie",
      slug: "katie",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "Our neighbour created a coffee shop from a cardboard box and allowed us to use their garden area." }],
    },
    {
      name: "olga",
      slug: "olga",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "I have never ordered this much stuff online before. I tried food delivery for the first time and have been buying coffee and other things online as well. A lot more recycling." }],
    },
  ],
  laptop: [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "video_1.mp4" },
        { url: "photo_1.jpg" },
        { url: "photo_2.jpg" },
        { url: "photo_3.jpg" },
        { url: "photo_4.jpg" },
        { url: "photo_5.jpg" },
      ],
    },
  ],
  looroll: [
    {
      name: "johanna",
      slug: "johanna",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4", caption: "We became somewhat creative with finding ways to entertain ourselves " }],
    },
  ],
  man: [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "14 March 2020 – “Free Loo Roll at Queens Road Peckham” – On an evening stroll to Peckham I chanced upon this public art installation, clearly made by someone with a sense of humour given all the recent media headlines about loo roll being out of stock." }],
    }

  ],
  mirror: [
    {
      name: "annmarie",
      slug: "annmarie",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "olga",
      slug: "olga",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "I have been doing more Tarot readings for myself than usual as I find I have had more time for considering life choices." }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }, { url: "photo_1.JPG" }],
    },
  ],
  movingboxes: [
    {
      name: "annmarie",
      slug: "annmarie",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "29 June 2020 – “Packing Up” – I spent a stressful few days flat-hunting and packing up all my stuff in preparation for moving out following my partner and I’s decision to split." }],
    },
  ],
  oven: [
    {
      name: "annmarie",
      slug: "annmarie",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }],
    },
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }, { url: "photo_2.jpg" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "28 June 2020 – “Franco Manca Takeaway Pizza” – I did a lot of cooking during lockdown, only relying on takeaway maybe one or two times. By late June there were more options available, including Franco Manca sourdough pizza in Greenwich. My partner and I picked up some takeaway pizza as a treat, knowing it would be one of our last meals together." }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "tarif",
      slug: "tarif",
      thumbnailUrl: "Beef_curry_1.jpg",
      media: [
       
      {content: "<b>My Cooking in the Lockdown</b><br>\
      <br>\
      April 30, 2020<br>\
      <br>\
      If you ask me what is my most favourite hobby, then I must include 'Cooking' as one of my first choices. I like to cook whenever I have time. This lockdown has given me a huge opportunity to practice different menus. My favourite dishes are usually our Bangladeshi cuisine. Most of them are based on curries. However, the food or food habits also varies in different parts of the country. I am from Dhaka, the capital and therefore my food and cooking is mostly based on traditional 'Dhakaiya' Cuisine. But I prefer and try to cook all types of Bengali dishes as I am a bit health conscious and most of the traditional cuisines of Dhaka are mainly based on refined oil that I try to avoid. Some of the famous foods of Dhaka include Biriyani, Pilau, Kebabs, Grilled chicken, Chicken tikka, Rezala, Different cakes, Puri etc.<br>\
      As I am living with my friends family, so most of the times our kitchen is occupied by my sisters in law as she is the queen of the kitchen and she cooks for all of us most of the times.  Although I have got more time to cook in the lockdown period, I could use the kitchen for few days due to my other schedule. Here are some of the dishes that I have cooked in different times during lockdown<br>\
      "},
        { url: "Beef_curry_1.jpg" },
      { url: "Beef_curry_2.jpg" },
      { url: "Chicken___vegtable_soup.jpg" },
      { url: "Pilau_Rice.jpg" },
      { url: "Eggs_with_Spinach.jpg" },
      { url: "Chicken_curry.jpg" },
      { url: "Jali_Kebab.jpg" },
      { url: "Jali_Kebab_2.jpg" },
      {content: "<b>My fasting (Ramadan) in the lockdown</b><br>\
      <br>\
      May 20, 2020<br>\
      <br>\
      As a Muslim we have to fast for a month which is called Ramadan in Hijri Calendar. This Ramadan (fasting) was different from my previous Ramadan as we had to perform everything in our house. Usually in Ramadan, we perform a special prayer in the mosque at night apart from our five times regular prayer. Many of us also break fast in the mosque as some mosques organise the rituals of  breaking fast in the mosque. However, all the activities were centred at home during this lockdown and so we were not allowed to perform any prayer in the mosques. Breaking fast is termed as Iftar where we eat and drink something. We start our fasting by eating and drinking (what we call Suhr) and break our fast by also eating and drinking.<br>\
      <br>\
      In our culture, sometimes we make special food for Iftar and send it to the neighbours, friends and other family members who live near us. Moreover, we also invite others or go to others' house to take Iftar and break fast together. But in this lockdown we couldn't do any of these activities. However, we get enough time to perform the whole time of this Ramadan with our family and this is the first time for most of us to perform everything of Ramadan at home with the family members. In this Ramadan, I went to bed after our morning prayer and got up before Noon prayer. Then after noon prayer, I read our Holy book (The Quran) and then played with my 4 years nephew (my friend's son) or study on my own course. Sometimes I read books. Then another prayer times arrive and it is afternoon prayer. After performing this sometimes I made Iftar for us, sometimes I did exercise or study as well. We break our fast when the evening prayer time starts. I prepared myself and sat with my friends' family to take Itar with them.<br>\
      <br>\
      After Iftar we congregated the prayer and then I started Reading the Holy book. Then I started study again. Sometimes I read Islamic books or did some Islamic courses online. Sometimes I had chat with my friend and his family before the night prayer. The night prayer was longer than usual as I have mentioned we have the special night prayer during Ramadan along with our usual mandatory prayer. After the main night prayer I spent most of my night with this special prayer. However, sometimes I pray  this prayer late night when I studied or did online courses between mandatory and special night prayer. After finishing my special prayer I took Suhr with my friend and his family. <br>\
      "
    }
      ],
    },
  ],
  parksign: [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg" },
        { url: "photo_2.jpg" },
        { url: "photo_3.jpg" },
        { url: "photo_4.jpg" },
        { url: "photo_5.jpg" },
        { url: "photo_6.jpg" },
        { url: "photo_7.jpg" },
      ],
    },
    {
      name: "katie",
      slug: "katie",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "My daughter became fascinated with nature" }, { url: "photo_2.jpg", caption: "In this photo she has a ladybird on the end of the stick. On days we couldn't find ladybirds I would make out that ants were rare creatures which we'd managed to locate. It appears you can always rely on finding an ant in summer." }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [
   
        { url: "video_1.mp4", g: true, caption: "30 June 2020 – “Video: Duck Family” – A video of the duck family at the Greenwich riverside on a breezy overcast day." },
        { url: "photo_1.jpg", g: true, caption: "26 March 2020 – “Greenwich Riverside Sunset” – One of my favourite sunset views. The water never fails to make me feel tranquil. As soon as the hard lockdown started, I knew I’d be relying a lot on walks and exercise outside the home to keep me sane." },
        { url: "photo_17.jpg", g: true, caption: "8 June 2020 – “My Partner at the Nature Reserve” – I’ve always been amused by the Sue Godfrey Local Nature Reserve, which sits between Deptford and Greenwich. I tend to cut across it on my walks but there’s nothing very interesting, scenic or even nature-y about it. I wonder how it ever came to be designated as a “nature reserve”…"},
        { url: "photo_18.jpg", g: true, caption: "8 June 2020 – “Bermondsey Riverside” – This riverside view sits at an entirely different angle than the Greenwich riverside so I enjoyed mixing it up and incorporating this route into some of my walks."},
        { url: "photo_19.jpg", g: true, caption: "15 June 2020 – “Greenwich Beach” – As we transitioned into summer, the tide started to get very low every time I went to the riverside. On this warm day it was so low that people were using the riverbank as a beach."},
        // { url: "photo_12(1).jpg" },
        { url: "photo_2.jpg", g: true, caption: "3 April 2020 – “Greenwich Park Overcast” – I went to Greenwich Park several times during lockdown. I have always enjoyed the view of the Thames, with Canary Wharf straight ahead and the Millennium Dome (aka O2 Arena) to the east."},
        { url: "photo_20.jpg", g: true, caption: "30 June 2020 –“Greenwich Duck Family” – By this stage, the ducklings had become teens!"},
        { url: "photo_3.jpg", g: true, caption: "3 April 2020 – “Greenwich Park Statue” – What struck me (aside from the nice composition of the sky, etc.) was how empty the park was due to the disappearance of tourists—almost eerie but also magical." },
        { url: "photo_4.jpg", g: true, caption: "3 April 2020 – “Secret Lookout Point View #1” – This secret lookout point west of Greenwich park called Point Hill was one of my discoveries during lockdown. It seemed a popular spot for watching the sunset." },
        { url: "photo_5.jpg", g: true, caption: "6 April 2020 – “Canary Wharf View” – One of my favourite views of Canary Wharf, which I got to enjoy every time I walked to the Greenwich riverside."},
        { url: "photo_6.jpg", g: true, caption: "16 April 2020 – “Greenwich Park View” – Another view from the park. I like the way the air was a bit hazy so the buildings look faded." },
        { url: "photo_7.jpg", g: true, caption: "16 April 2020 – “My Partner in Greenwich Park” – Despite our eventual break-up, my partner and I felt that lockdown was harmonious because we got into a rhythm of doing walks together, eating meals together, and watching shows together. Splitting up was never a question of not getting along or not loving each other—it was more of a case of feeling complacent and realising that our love had lost its romantic component." },
        { url: "photo_10.jpg", g: true, caption: "20 May 2020 – “Thames Beach Isle of Dogs” – This part of the Thames riverside, on the eastern bank of the Isle of Dogs, was another of my lockdown discoveries. I loved the view of the Millennium Dome across the river." },
        { url: "photo_8.jpg", g: true, caption: "1 May 2020 – “Pigeon Perching” – I spotted this pigeon on a branch in Margaret McMillan Park, which connects Deptford to New Cross station. Normally pigeons tend to be on the ground, so I thought it was funny that this one was perching. During lockdown I became much more alert to wildlife, especially birds." },
        // { url: "photo_9.jpg" },
        { url: "photo_11.jpg", g: true, caption: "21 May 2020 – “Blythe Hill” – Located between Honor Oak and Catford, Blythe Hill is another gem of southeast London with great views of the City." },
        { url: "photo_20.jpg", g: true, caption: "29 May 2020 – “Greenwich Ducklings” – There seemed to be a surplus of ducklings this spring and I loved spotting them every time I went to the riverside." },
        { url: "photo_12.jpg", g: true, caption: "30 May 2020 – “Burgess Park Family Fun” – Another visit to Burgess Park. I especially love that the park is very family-friendly." },
        { url: "photo_13.jpg", g: true, caption: "30 May 2020 – “Burgess Park Sunbathers” – I had never seen so many people sunbathing all at once! With people unable to go on holiday, it was nice to see London parks becoming outlets for mass leisure." },
        { url: "photo_14.jpg", g: true, caption: "29 May 2020 – “Folkestone Skate Park” – I enjoyed observing all the kids and teens using the skate park throughout lockdown and really resented all the NIMBYs on social media who were critical of kids enjoying public spaces during lockdown." },
        { url: "photo_16.jpg", g: true, caption: "25 May 2020 – “Burgess Park Pond” – Burgess Park might just be my favourite park in southeast London, because of how lively it gets. Although it’s a longer walk from Deptford than other parks, I didn’t mind strolling there on warm days." },
      ],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }, { url: "photo_2.jpg" }],
    },
  ],
  phone: [
    {
      name: "megan",
      slug: "megan",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "Communication.mp4" },
        // { url: "audio_1.m4a" },
        // { url: "photo_1.jpeg" },
      ],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl:
        "Copy-of-20.06.20_Mask_Selfie.jpg",
      media: [{ url: "Copy-of-20.06.20_Mask_Selfie.jpg", caption: "20 June 2020 – “Mask Selfie” – Masks became compulsory on the transport network on the 15th so I took this photo of myself donning a mask on a Southeastern rail service later that week." }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "video_1.mp4" },
        { url: "photo_1.jpg" },
        { url: "photo_2.jpg" },
        { url: "photo_3.jpg" },
      ],
    },
  ],
  placards: [
    {
      name: "aga",
      slug: "aga",
      thumbnailUrl:
        "photo_1.jpg",
      media: [
        { url: "photo_1.jpg", caption: "Charity Starts @ home' Pictures from preparations to protests along campaigns *Can’t pay, Won’t pay* and *Stop the evictions at Somerford Grove!*" },
        { url: "photo_2.jpg", caption: "I joined members of London Renters Union to support residents at Somerford Grove https://somerfordgroverenters.com/ who organised to fight back billionaire landlord John Christodoulou, who threatened renters after they requested a rent reduction during a pandemic. They were met with acts of harassment and intimidation by Christodoulou’s lawyers and security staff and many tenants were issued eviction notices." },
        { url: "photo_3.jpg", caption: "Paradoxically the same landlord is not only a businessman but also a philanthropist who runs a charity so we made a banner with my children and neighbour to remind him that ‘charity starts @ home’." },
        { url: "photo_4.jpg" },
      ],
    },
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg", caption: "6 June 2020 – “Black Lives Matter New Cross” – June saw the spread of BLM protests as a reaction to George Floyd’s death in the US. It was good to see solidarity for the movement in the local area. I also have no doubt that the angst and ennui brought on my lockdown probably contributed to people feeling galvanised to be part of a movement and take to the streets." },
        { url: "photo_2.jpg", caption: "6 June 2020 – “Wash Ur Hands” – A house on New Cross Road promoted a public health message." },
        { url: "photo_3.jpg", caption: "20 June 2020 – “Defend Black Lives Defund the Police” – Graffiti on the high street." },
      ],
    },
  ],
  plants: [
    {
      name: "olga",
      slug: "olga",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "I started collecting grasses from my walks in the nearby park as a kind of calming hobby." }],
    },
  ],
  playmat: [
    {
      name: "katie",
      slug: "katie",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "Another neighbour and local best selling author/ illustrator would meet my daughter on the communal stairs and read books to her." }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }, { url: "video_2.mp4" }, { url: "video_3.mp4" }],
    },
  ],
  queue: [
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }, { url: "photo_2.jpg" }],
    },
    {
      name: "nuria",
      slug: "nuria", thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "video_1.mp4" },
        { url: "photo_1.jpg" },
        { url: "photo_2.jpg" },
        { url: "photo_3.jpg" },
        { url: "photo_4.jpg" },
        { url: "photo_5.jpg" },
        { url: "photo_6.jpg" },
        { url: "photo_7.jpg" },
        { url: "photo_8.jpg" },
        { url: "photo_1_queue.jpg", caption: "22 April 2020 – “Queue at ASDA” – By now social distancing measures were being implemented across essential shops. The upside of living on the high street is having a wide range of shops at your disposal, so I was always able to avoid long queues . I tried to time my shopping so that I would go at less busy periods. Trips to the shop became a daily pastime during lockdown and I took pleasure in selecting new ingredients and food items." }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }],
    },
  ],
  scissors: [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1b.jpg",
      media: [{ url: "photo_1b.jpg" }, { url: "photo_2.jpg" }],
    },
    {
      name: "johanna",
      slug: "johanna",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "Pampering and experimenting. I let my daughter bleach some highlights to my hair and we tried to colour it purple but that didn't stick for some reason. I felt more free to allow her to experiment on my hair as I wasn't going anywhere. " }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }, { url: "video_2.mp4" }],
    },
  ],
  shop: [
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }, { url: "photo_2.jpg" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "video_1.mp4" },
        { url: "photo_1.jpg" },
        { url: "photo_2.jpg" },
        { url: "photo_3.jpg" },
        { url: "photo_4.jpg" },
        { url: "photo_5.jpg" },
        { url: "photo_6.jpg" },
        { url: "photo_7.jpg" },
        { url: "photo_8.jpg" },
        { url: "photo_9.mp4" },
      ],
    },
  ],
  sink: [
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "video_1.mp4" }
      ]
    }
  ],
  someoneelse_swindow: [
    {
      name: "katie",
      slug: "katie",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4", caption: "My neighbour (who is a world famous opera star) and his concert pianist boyfriend held a mini concert for the neighbours (3 adults, 1 toddler and 2 cats) from their window." }, { url: "photo_1.jpg", caption: "This was picked up by radio 1 and broadcast live." }],
    },
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "8 April 2020 – “Socially Distanced Visit” – My partner (now fully symptom-less) and I walked to Brixton to “meet” with two friends. They wanted to stick to the rules so chatted to us from their windows. It was nice to see them." }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "video_1.mp4" },
        { url: "video_2.mp4" },
        { url: "video_3.mp4" },
        { url: "video_4.mp4" },
        { url: "video_5.mp4" },
      ],
    },
    {
      name: "katie",
      slug: "katie",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "video_1.mp4" }
      ]
    },
  ],
  streetsign: [
    {
      name: "brian",
      slug: "brian",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg" },
        { url: "photo_2.jpg" },
        { url: "photo_3.jpg" },
        { url: "photo_4.jpg" },
        { url: "photo_5.jpg" },
        { url: "photo_6.jpg" },
        { url: "photo_7.jpg" },
      ],
    },
    {
      name: "katie",
      slug: "katie",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg", caption: "Train stations offered shelter from the rain as well as toddler entertainment. They became one of the only places outside the flat where I could feed the newborn and toddler as most cafes and communal spaces were closed. They were a lifeline for the days my partner had confidential meetings or work related court work and we needed to be outside. The previous hub and chaos of train stations soo because a distant memory and the platforms and trains where often totally empty." },
        { url: "photo_2.jpg", caption: "Diggers also became a focal point of many day." },
        { url: "photo_3.jpg", caption: "The sand strip in deptford became our 'beach'" },
      ],
    },
    {
      name: "melissa",
      slug: "melissa",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg" },
        { url: "photo_2.jpg" },
        { url: "photo_3.jpg" },
      ],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg", caption: "6 April 2020 – “Empty Square Deptford” – I have always loved that Deptford has two pedestrianised squares where people can hang out. One is by Albany Theatre and this one is by Deptford Lounge (where the library is located). Normally there are people who sit on the benches and hang out, drinking and chatting. I was really shocked during the initial phase of lockdown that everyone seemed to disappear. Around late April/May, the usual groups of loiterers started to reappear and I welcomed the sense of normality they helped to re-establish. I was disheartened and infuriated when I saw local residents complain about their presence on the NextDoor forum on the grounds that they were not observing social distancing." },
        { url: "photo_10.jpg", caption: "17 June 2020 – “South Dock Canary Wharf” – Parts of Canary Wharf always feel futuristic to me and I continued to find the vibe extra special in light of there not being any office workers blighting the landscape." },
        { url: "photo_11.jpg", caption: "17 June 2020 – “The Return of Rain” – The good weather couldn’t last indefinitely. The closer we got to summer, the more spring-like the weather got, much to my disappointment." },
        { url: "photo_12.jpg", caption: "17 June 2020 – “Thunderstorm” – On this day the rain picked up until it was a full-on thunderstorm, which was actually quite exciting." },
        { url: "photo_2.jpg", caption: "21 June 2020 – “Dusk on the Thames” – One of my favourite views when walking along the Greenwich Riverside." },
        { url: "photo_3.jpg", caption: "28 June 2020 – “Mural, New Cross House” – This is a piece by street artist Deanio X which I thought did a good job of celebrating black contributions to healthcare and the suffering of black patients. I like that Deptford and surrounding areas display their share of street art (not as much as East London, but still a decent amount)." },
        { url: "photo_4.jpg", caption: "???" },
        { url: "photo_5.jpg", caption: "1 May 2020 – “‘Fear Is the Virus’” – This graffiti appeared on a wall across from the Albany Theatre in Deptford. Every time I walked or cycled past I found myself nodding in approval. By this stage of the pandemic, with the NHS coping and the numbers of deaths/hospitalisations in decline, it did seem to me like fearfulness, paranoia and media hysteria were becoming the bigger issues. I was starting to feel that restrictions needed to be eased quicker so that livelihoods could be salvaged and a sense of normality could be reinstated. It also boggled my mind that there weren’t plans to get kids back to school, given the negative impact of online-only classes." },
        { url: "photo_6.jpg", caption: "14 May 2020 – “NHS Rainbow” – Seemingly overnight this awesome mural appeared on Evelyn Road near Surrey Quays. I clapped for the NHS during the first couple Thursdays, but not beyond that. I appreciated seeing the spontaneous artworks commemorating the NHS, but at the same time felt like the hero-worship narrative ceased to be as relevant once we got over the initial peak of the pandemic." },
        { url: "photo_7.jpg", caption: "6 June 2020 – “Goldsmiths New Cross” – The Goldsmiths “spaghetti” building is an iconic piece of architecture in the local area. During lockdown there was a visible decline in student life and I felt worried about the future of education in general. I was aware that such a disruption in students’ lives could have long-term consequences." },
        { url: "photo_8.jpg", caption: "8 June 2020 – “Greenwich Foot Tunnel” – I would cross the tunnel to make my way to the Ise of Dogs. I have always felt that the tunnel has a sort of dystopic vibe, like an underground bunker." },
        { url: "photo_9.jpg", caption: "16 June 2020 – “Fuck USA #1” – A tagger around Deptford expressed anger at US police brutality, spray-painting the phrase “fuck USA” across the area. It was a sentiment that I felt was not unfounded, and I was glad to see that people could make a distinction between the problem of police violence in the US and the problems we have in the UK, where there is institutionalised racism but not the same level of systemic violence." },
      ],
    },
    {
      name: "sarah",
      slug: "sarah",
      thumbnailUrl: "photo_1.JPG",
      media: [{ url: "photo_1.JPG" }],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }, { url: "photo_2.jpg" }],
    },
  ],
  tv: [
    {
      name: "holly",
      slug: "holly",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "video_1.mp4" }
      ]
    }
  ],
  window: [
    {
      name: "annmarie",
      slug: "annmarie",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "katie",
      slug: "katie",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg", caption: "The mundane became our entertainment. My daughter became fascinated by the refuse collectors and we'd run to the window every Thursday morning so she could see them. This refuse collector would wave and dance the whole way down the street and still does to entertain her." }],
    },
    {
      name: "melissa",
      slug: "melssa",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "nuria",
      slug: "nuria",
      thumbnailUrl: "photo_1.jpg",
      media: [
        { url: "photo_1.jpg", caption: "20 May 2020 – “Cops on Horses (View from My Balcony #1)” – Every day two mounted officers patrolled the high street at a particular time. I enjoyed hearing the trotting of the horses and poking my head out the balcony to observe them. Throughout lockdown I felt reassured by the laissez-faire response of our local Met force in Lewisham. I never saw them approach anyone or act intrusively. It’s fair to say they have the trust and respect of the local community." },
        { url: "photo_2.jpg", caption: "30 May 2020 – “Cops on Horses Balcony View #2” – Another shot of mounted cops trotting through the high street." },
        { url: "video_1.mp4", caption: "17 March 2020 – “Video taken from my balcony” – I took this video from my balcony because I wanted to show my parents the vibe on the high street during that first week of “soft” lockdown. It was a sunny Tuesday and I was pleased to see that many people in the neighbourhood continued about their daily lives." },
      ],
    },
    {
      name: "sylvia",
      slug: "sylvia",
      thumbnailUrl: "photo_1.jpg",
      media: [{ url: "photo_1.jpg" }],
    },
    {
      name: "johanna",
      slug: "johanna",
      thumbnailUrl: "IMG_8400.jpeg",
      media: [{ url: "IMG_8400.jpeg"}]
    }
  ],
  yogamatt: [
    {
      name: "johanna",
      slug: "johanna",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4", caption: "I'd usually attend a pilates class every Monday. Now I've been doing one every other day in lockdown as my friend Brooke began recording and posting lessons daily. Many instructors are encouraging people to pay what they can if anything for the lessons which I like and it feels more...." }, { url: "photo_1.jpeg", caption: "Our living room became a real multi-use spac. I taught my daugther how to do French sipping and she practiced it whilst I was working. Here she's taking a break from her practice. The yoga mat has become a permanent element on the floor and we have been dancing, doing hiit classes, virtual football practice, pilates and mastered tik tok routines there." }],
    },
    {
      name: "tarif",
      slug: "tarif",
      thumbnailUrl: "Video thumbnail/video_1_thumb.png",
      media: [{ url: "video_1.mp4" }],
    },
    {
      name: "megan",
      slug: "megan",
      thumbnailUrl:
        "Video thumbnail/video_1_thumb.png",
      media: [
        { url: "Exercise.mp4" },
        { url: "video_1.mp4" },
        { url: "video_2.mp4" },
        { url: "video_3.mp4" },
      ],
    },
  ],
}

export default contentList
