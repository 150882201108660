import React from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"
import Nameplate from "ui/Nameplate"
import { toUrl } from "../utils"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "red",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root")

export default function PersonThumbnails({ content, onClick, contentId }) {
  const thumbnailUrl = content.thumbnailUrl.includes("http")
    ? content.thumbnailUrl
    : `https://lockdown-idoc.s3-eu-west-1.amazonaws.com/objects2/object_${contentId}/person_${content.slug}/${content.thumbnailUrl}`
  return (
    <>
      <div
        style={{ position: "relative", width: "300px", textAlign: "center" }}
      >
        <div style={{ height: "200px" }}>
          <img
            src={thumbnailUrl}
            onClick={() => onClick(content)}
            className="content-thumbnail"
          />
        </div>
        <Nameplate name={content.name}></Nameplate>
      </div>
    </>
  )
}
