import React from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"
import { toUrl } from "../utils"
import cornerPaper from "Assets/popup/endbox/Endbox.png"
import button from "Assets/popup/endbox/Check_box_end.png"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: 1000,
    height: 400,
    marginRight: "-50%",
    marginTop: 0,
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF00",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    border: "none",
    backgroundImage: toUrl(cornerPaper),
    overflow: "visible",
    zIndex: 99999,
    paddingLeft: 30,
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.0)",
    zIndex: 99999,
  },
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root")

export default function EndBox({ popup, onClick }) {
  React.useEffect(() => {
    if (popup) {
      setIsOpen(true)
    }
  }, [popup])

  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [hasSetTrigger, setHasSetTrigger] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  //   const triggerPopup = (popupName) => {
  //     setPopup(popupName)
  //     setIsOpen(true)
  //   }

  return (
    <>
      <Modal
        isOpen={popup}
        // onRequestClose={closeModal}
        // className="s"
        style={customStyles}
        contentLabel="Example Modal"
      >
        <>
          <div
            className=""
            style={{ fontSize: "1.4em", width: "100%", marginTop: 65 }}
          >
            Wow it feels like this lockdown has been forever! Want to escape?...
          </div>
          <div style={{ position: "absolute", bottom: 30 }}>
            <PopupButton onClick={onClick}>
              a. Make an illegal trip to your countryside property!?
            </PopupButton>
            <PopupButton onClick={onClick}>
              b. Take your chances with heard immunity!?
            </PopupButton>
            <PopupButton onClick={onClick}>
              c. Boost your vitamin C and hope for the best? 
            </PopupButton>
            {/* <PopupButton onClick={onClick}>
              d. Suck it up and wait for the vaccine?
            </PopupButton> */}
            <PopupButton onClick={onClick}>
              d. Have some fun then come back for lockdown 2?
            </PopupButton>
            <PopupButton onClick={onClick}>
              e. Embrace your newfound social anxiety and stay in lockdown?
            </PopupButton>
          </div>
        </>
      </Modal>
    </>
  )
}

const PopupButton = ({ children, onClick }) => {
  return (
    <div
      style={{
        backgroundImage: toUrl(button),
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        height: 52,
        width: 800,
        marginBottom: 5,
        fontSize: "1.3em",
        display: "inline-flex",
        justifyContent: "left",
        alignItems: "center",
        marginRight: 20,
        cursor: "pointer",
        textAlign: "left",
        paddingLeft: 20,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
