import React from "react"
import Item from "./Item"
import Info from "../Info/Info"
import { toUrl } from "../utils"
import inside from "Assets/inside.mp3"
import outside from "Assets/outside.mp3"
import door from "Assets/misc/door_white_small.png"
import arrowLeft from "Assets/misc/white_arrow_left.png"
import arrowRight from "Assets/misc/white_arrow_right.png"
import mute from "Assets/misc/mute.png"
import nomute from "Assets/misc/sound.png"
import useSound from "use-sound"
import Popup from "ui/Popup"
import EndBox from "ui/EndBox"
import Placenames from "ui/Placenames"

const Scene = ({
  scene,
  changeRoom,
  increaseSanity,
  increaseRisk,
  children,
  risk,
  sanity,
  entryTime,
  returnToWelcome,
}) => {
  const [popup, setPopup] = React.useState(() => {})
  const [playSounds, setPlaySounds] = React.useState(true)
  const [openItem, setOpenItem] = React.useState()
  const [itemIsOpen, setItemIsOpen] = React.useState(false)
  const [triggeredPopups, setTriggeredPopups] = React.useState([])
  const [isInside, setIsInside] = React.useState(true)
  const [timeToExit, setTimeToExit] = React.useState(false)

  const [infoModalOpen, setInfoModalOpen] = React.useState(true)
  const [isFirstTime, setIsFirstTime] = React.useState(true)

  const leavingTime = new Date(entryTime)
  leavingTime.setMinutes(leavingTime.getMinutes() + 15)
  //console.log(leavingTime, entryTime)
  if (leavingTime < new Date() && !timeToExit) {
    setTimeToExit(true)
  }

  React.useEffect(() => {
    if (risk > 3) {
      triggerPopup("test")
    }
  }, [risk])

  React.useEffect(() => {
    if (sanity < 1) {
      triggerPopup("speak_to_someone")
    }
  }, [sanity])

  const [playInside, { stop: stopInside, isPlaying: insideIsPlaying }] =
    useSound(inside, {
      interrupt: false,
    })
  const [playOutside, { stop: stopOutside, isPlaying: outsideIsPlaying }] =
    useSound(outside, {
      interrupt: false,
    })

  const Mute = ({ onClick, playSounds }) => (
    <img
      src={playSounds ? nomute : mute}
      onClick={onClick}
      className={`mute-button`}
    />
  )

  const toggleMute = () => {
    if (playSounds) {
      console.log("muting")
      stopInside()
      stopOutside()
      setPlaySounds(false)
    } else {
      console.log("playing")
      if (isInside) {
        playInside()
      } else {
        playOutside()
      }
      setPlaySounds(true)
    }
  }

  if (!insideIsPlaying && !outsideIsPlaying && playSounds) {
    playInside()
  }

  const triggerPopup = (name) => {
    if (triggeredPopups.includes(name)) {
      // don't do anything
    } else {
      setTriggeredPopups(triggeredPopups.concat([name]))
      setPopup(name)
    }
  }

  const { backgroundUrl, name } = scene
  const move = (newLocation, skipPopups) => () => {
    setOpenItem("")
    console.log("moving to " + newLocation)
    // Maybe play a popup

    const showPopup = Math.random() > 0.5 && !skipPopups
    const inside_popups = [
      "zoom",
      "instagram",
      "bleach",
      "hour_outside",
      "toiletpaper",
    ]
    const outside_popups = ["cummings", "mask", "handswash"]
    let popup_to_show
    if (isInside && showPopup) {
      popup_to_show =
        inside_popups[Math.floor(Math.random() * inside_popups.length)]
      triggerPopup(popup_to_show)
    }
    if (!isInside && showPopup) {
      popup_to_show =
        outside_popups[Math.floor(Math.random() * outside_popups.length)]
      triggerPopup(popup_to_show)
    }
    changeRoom(newLocation)
    // increaseSanity(1)
    // increaseRisk(-1)
  }

  const rightArrow = scene.right ? (
    <Arrow right onClick={move(scene.right)} />
  ) : null
  const upArrow = scene.up ? (
    <Arrow
      up
      onClick={() => {
        if (scene.goesTo === "inside") {
          triggerPopup("handgel")
          setIsInside(true)
          playSounds && playInside()
          stopOutside()
        } else {
          setIsInside(false)
          playSounds && playOutside()
          stopInside()
        }
        move(scene.up)()
      }}
    />
  ) : null
  const leftArrow = scene.left ? <Arrow onClick={move(scene.left)} /> : null
  const items = scene.items.map((item) => (
    <Item
      isOpen={openItem === item.contentId}
      key={item.title}
      onClick={() => {
        setOpenItem()
        increaseSanity(item.sanity || 0)
        increaseRisk(item.risk || 0)
      }}
      item={item}
      onClose={() => {
        setItemIsOpen(false)
        setOpenItem()
      }}
      onOpen={() => setItemIsOpen(true)}
    />
  ))

  const showArrows = !itemIsOpen && !openItem && !infoModalOpen
  console.log("OPen item", openItem, showArrows)
  const infoItem = <Info infoModalOpen={infoModalOpen} setInfoModalOpen={setInfoModalOpen} isFirstTime={isFirstTime} setIsFirstTime={setIsFirstTime}></Info>
  const muteItem = <Mute playSounds={playSounds} onClick={toggleMute}></Mute>

  const placenames = <Placenames name={name}></Placenames>

  return (
    <div className="scene" style={{ backgroundImage: toUrl(backgroundUrl) }}>
      {children}
      {showArrows && rightArrow}
      {showArrows && leftArrow}
      {showArrows && upArrow}
      {items}
      {infoItem}
      {muteItem}
      {showArrows && placenames}
      <Popup
        increaseSanity={increaseSanity}
        increaseRisk={increaseRisk}
        setIsInside={setIsInside}
        setOpenItem={setOpenItem}
        move={(location) => {
          move(location, true)()
        }}
        popup={popup}
      ></Popup>

      <EndBox onClick={returnToWelcome} popup={timeToExit}></EndBox>
    </div>
  )
}
export default Scene

const Arrow = ({ right, up, onClick }) => {
  const style = right ? { left: 240 } : up ? { left: 240,  transform: "scale(1.4)"  } : { right: 240     }
  return (
    <div className="arrow" style={style} onClick={onClick}>
      {right ? (
        <img style={{ height: 70 }} src={arrowLeft} />
      ) : up ? (
        <img style={{ height: 70 }} src={door} />
      ) : (
        <img style={{ height: 70 }} src={arrowRight} />
      )}
    </div>
  )
}
