import bathroom from "Assets/placenames/bathroom.png"
import communityGarden from "Assets/placenames/community-garden.png"
import kitchen from "Assets/placenames/kitchen.png"
import park from "Assets/placenames/park.png"
import street from "Assets/placenames/street.png"
import bedroom from "Assets/placenames/bedroom.png"
import garden from "Assets/placenames/garden.png"
import livingRoom from "Assets/placenames/living-room.png"
import shops from "Assets/placenames/shops.png"
import vista from "Assets/placenames/vista.png"
import { toUrl } from "../utils"

import longPaper from "Assets/paper/long_scrap_of_paper.png"

const nameToImage = {
  bathroom: bathroom,
  community_garden: communityGarden,
  kitchen: kitchen,
  park: park,
  outside_house: street,
  bedroom: bedroom,
  garden: garden,
  lounge: livingRoom,
  shop: shops,
  vista: vista,
}

const Placenames = ({ name, onClick, isSelected, small }) => (
  <img
    onClick={onClick}
    className={`placename ${small && "small"}`}
    style={{ backgroundImage: toUrl(longPaper) }}
    src={nameToImage[name]}
  />
)

export default Placenames
