
import { toUrl } from "../utils"
import peri1 from 'Assets/peri/peri1.png'
import peri2 from 'Assets/peri/peri2.png'
import peri3 from 'Assets/peri/peri3.png'
import peri4 from 'Assets/peri/peri4.png'
import peri5 from 'Assets/peri/peri5.png'

const peris = [peri1, peri2, peri3, peri4, peri5]

const Chili = ({value}) => {

    return <img className="chili" src={peris[value || 0]} />
}
export default Chili