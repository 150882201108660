import React from "react"

const Media = ({ media, urlPrefix }) => {
  const [openItem, setOpenItem] = React.useState(0)
  function moveRight() {
    setOpenItem(openItem + 1)
  }
  function moveLeft() {
    setOpenItem(openItem - 1)
  }

  const showLeftArrow = openItem > 0
  const showRightArrow = openItem < media.length - 1

  if (!media.length) return "No media"

  const caption = media[openItem]?.caption
  const hasCaption = !!caption

  const url = media[openItem]?.url
  const content = media[openItem]?.content

  const isVideo =
    url &&
    (url.includes(".mp4") || url.includes(".mov") || url.includes(".MOV"))

  const isContent = !!content

  const imageUrl =
    url && url.includes("http")
      ? url
      : `https://lockdown-idoc.s3-eu-west-1.amazonaws.com/${urlPrefix}${url}`
  return (
    <div className="media-wrapper">
      {showLeftArrow && (
        <div onClick={moveLeft}>
          <img className="media-arrow left" src="/misc/arrow left.png"></img>{" "}
        </div>
      )}
      {isVideo && (
        <video
          key={url}
          className="media-video"
          controls={1}
          autoplay={1}
          width="700"
          height="430"
        >
          <source
            src={`https://lockdown-idoc.s3-eu-west-1.amazonaws.com/${urlPrefix}${media[openItem]?.url}`}
          ></source>
        </video>
      )}

      {!isVideo && !isContent && <img className="media-item" src={imageUrl}></img>}
      {hasCaption && <div className="caption">{caption}</div>}
      {isContent && <div className="content" dangerouslySetInnerHTML={{__html: content}}/>}
      {showRightArrow && (
        <div onClick={moveRight}>
          {" "}
          <img
            className="media-arrow right"
            src="/misc/arrow right.png"
          ></img>{" "}
        </div>
      )}
    </div>
  )
}

export default Media
