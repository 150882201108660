import React from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"
import { toUrl } from "../utils"
import redPaper from "Assets/paper/red_paper.png"
import back from "Assets/paper/back.png"
import continuePaper from "Assets/paper/continue.png"
import cornerPaper from "Assets/paper/corner_paper.png"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: 940,
    height: 570,
    marginRight: "-50%",
    marginTop: 0,
    transform: "translate(-50%, -50%)",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#2255BBEE",
    backgroundSize: "contain",
    border: "none",
    overflow: "visible",
    zIndex: 99,
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.0)",
  },
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root")

export default function Info({infoModalOpen, setInfoModalOpen, isFirstTime, setIsFirstTime}) {

  function openModal() {
    setInfoModalOpen(true)
  }

  function closeModal() {
    setIsFirstTime(false)
    setInfoModalOpen(false)
  }

  return (
    <>
      <Modal
        isOpen={infoModalOpen}
        onRequestClose={closeModal}
        // className="s"
        style={customStyles}
        contentLabel="Example Modal"
      >
        <>
          <div
            onClick={() => setInfoModalOpen(false)}
            className="item-title-container"
            style={{
              backgroundImage: toUrl(cornerPaper),
            }}
          >
            <h2>Info</h2>
          </div>
          <div
            className="zoomer infobox"
            // style={{ backgroundImage: toUrl(item.image) }}
          >
            <h1>Welcome to The Lockdown Game!</h1>
            <p>
              This is a web documentary made by a group of Londoners about their
              experiences of the UK’s first national lockdown, during the Covid
              Pandemic in Spring 2020.{" "}
            </p>
            <p>
              It was made as part of a research project led by Dr Ella Harris,
              funded by The Leverhulme Trust. Decisions about the web doc’s
              design were decided by the group and put together by web developer
              Michael Skelly and Artist Jack Scott.
            </p>
            <p>
              You can explore the different indoor and outdoor areas of The
              Lockdown Game by using the arrows to move between spaces. Some of
              the objects in the rooms and outdoor areas will glow if you hover
              over them. Click these to see the group’s multimedia content about
              their lives under lockdown!
            </p>
            <p>
              In the bottom corners of the screen are your wellbeing meter and
              viral risk level. Watch out for these, they will alter as you move
              about and interact with different content!
            </p>

            <p>Enjoy lockdown! </p>
          </div>

          <div
            onClick={closeModal}
            className="backButtonContainer info-close-button"
            style={{
              backgroundImage: toUrl(redPaper),
            }}
          >
            {isFirstTime ?
            <img className="backButton" style={{width: 200, height: 40}} src={continuePaper} />
            :
            <img className="backButton" src={back} />
}
          </div>
        </>
      </Modal>

      <img
        src="/misc/Info symbol.png"
        onClick={openModal}
        className="info-button"
      />
    </>
  )
}
