import React from "react"
import Nameplate from "ui/Nameplate"
import PersonThumbnails from "./PersonThumbnails"
import contentList from "./contentList"
import Media from "./Media"

export default function ObjectContents({ contentId }) {
  const [activeContent, setActiveContent] = React.useState(null)

  let contents = contentList[contentId] || []

  const personSwitcher = (
    <div className="name-list">
      {contents.map((content) => (
        <Nameplate
          isSelected={activeContent?.name == content.name}
          onClick={() => setActiveContent(content)}
          name={content.name}
          small
        ></Nameplate>
      ))}
    </div>
  )

  if (activeContent) {
    return (
      <div className="media-container">
        <Media
          key={activeContent?.name}
          media={activeContent.media}
          urlPrefix={`objects2/object_${contentId}/person_${activeContent.slug}/`}
        ></Media>
        {personSwitcher}
      </div>
    )
  }
  return (
    <div className="people-list">
      {contents.map((c) => (
        <PersonThumbnails
          contentId={contentId}
          onClick={setActiveContent}
          content={c}
        ></PersonThumbnails>
      ))}
    </div>
  )
}
