import Aga from "Assets/names/Aga.png"
import Anon_1 from "Assets/names/Anon-1.png"
import Anon_3 from "Assets/names/Anon-3.png"
import Anon_5 from "Assets/names/Anon-5.png"
import Holly from "Assets/names/Holly.png"
import Katie from "Assets/names/Katie.png"
import Melissa from "Assets/names/Melissa.png"
import Olga from "Assets/names/Olga.png"
import Sylvia from "Assets/names/Sylvia.png"
import Ann_Marie from "Assets/names/Ann-Marie.png"
import Anon_2 from "Assets/names/Anon-2.png"
import Anon_4 from "Assets/names/Anon-4.png"
import Brian from "Assets/names/Brian.png"
import Johanna from "Assets/names/Johanna.png"
import Megan from "Assets/names/Megan.png"
import Nuria from "Assets/names/Nuria.png"
import Sarah from "Assets/names/Sarah.png"
import Tariful from "Assets/names/Tariful.png"
import Anne from "Assets/names/Anne.png"
import Beth from "Assets/names/Beth.png"
import Feda from "Assets/names/Feda.png"

const nameToImage = {
  aga: Aga,
  anon_1: Anon_1,
  anon_3: Anon_3,
  anon_5: Anon_5,
  holly: Holly,
  katie: Beth,
  melissa: Melissa,
  olga: Olga,
  sylvia: Sylvia,
  annmarie: Ann_Marie,
  anon_2: Anon_2,
  anon_4: Anon_4,
  brian: Brian,
  johanna: Johanna,
  megan: Megan,
  nuria: Nuria,
  sarah: Sarah,
  tarif: Feda,
}

const Nameplate = ({ name, onClick, isSelected, small }) => (
  <img
    onClick={onClick}
    className={`nameplate ${small && "small"}`}
    style={{ backgroundColor: isSelected ? "yellow" : "#FFFFFF00" }}
    src={nameToImage[name]}
  />
)

export default Nameplate
