const sceneList = {
  lounge: {
    backgroundUrl: "scenes/living_room_background.jpg",
    left: "bedroom",
    right: "kitchen",
    items: [
      {
        title: "Window",
        contentId: "window",
        risk: -1,
        sanity: 1,
        image: "scenes/living_room/Window.png",
        style: {
          top: 0,
          left: 270,
          //width: 520,
        },
      },
      {
        title: "TV",
        contentId: "tv",
        links: `<h3>Boris Hospitalized</h3>
        <a target="_blank" href='https://www.dailymail.co.uk/news/article-8189869/Boris-Johnson-admitted-hospital-testing-positive-coronavirus.html'>https://www.dailymail.co.uk</a>
        <h3>Dominic Cummings eyesight story</h3>
        <a target="_blank" href='https://www.bbc.co.uk/news/av/uk-52801667'>https://www.bbc.co.uk</a>`,

        risk: -1,
        sanity: -1,
        image: "scenes/living_room/TV.png",
        style: {
          top: 120,
          left: 67,
        },
      },
      {
        title: "Guitar",
        contentId: "guitar",
        risk: -1,
        sanity: 1,
        image: "scenes/living_room/Guitar.png",
        style: {
          bottom: 0,
          right: 0,
        },
      },
      {
        title: "Table",
        contentId: "desk",
        risk: -1,
        sanity: -1,
        image: "scenes/living_room/Table.png",
        style: {
          top: 230,
          right: 0,
        },
      },
      {
        title: "Laptop",
        contentId: "laptop",
        risk: -1,
        sanity: 1,
        image: "scenes/living_room/Laptop.png",
        style: {
          top: 183,
          right: 135,
        },
      },

      {
        title: "Collage",
        contentId: "collageposter",
        risk: -1,
        sanity: 1,
        image: "scenes/living_room/Quarantine_collage.png",
        style: {
          top: 0,
          right: 0,
        },
      },
      {
        title: "Phone",
        contentId: "phone",
        links: `<h3>Timeline of Lockdown</h3>
        <a target="_blank" href='https://www.independent.co.uk/news/uk/home-news/coronavirus-three-tier-lockdown-restrictions-timeline-boris-johnson-b987008.html'>https://www.independent.co.uk</a>`,

        risk: -1,
        sanity: 1,
        image: "scenes/living_room/Phone.png",
        style: {
          top: 283,
          right: 58,
        },
      },
      {
        title: "Moving Box",
        contentId: "movingboxes",

        risk: 1,
        sanity: 1,
        image: "scenes/living_room/Moving_box.png",
        style: {
          bottom: 0,
          left: 0,
        },
      },
    ],
  },
  bedroom: {
    backgroundUrl: "scenes/bedroom_background.jpg",
    right: "lounge",
    left: "bathroom",
    items: [
      {
        title: "Cupboard",
        contentId: "cupboard",

        risk: 1,
        sanity: 1,
        image: "scenes/bedroom/Cupboard.png",
        style: {
          top: 0,
          left: 57,
        },
      },
      {
        title: "Bed",
        links: `<h3>Anxiety tips</h3>
        <a target="_blank" href='https://www.nhs.uk/oneyou/every-mind-matters/coronavirus-covid-19-anxiety-tips/'>https://www.nhs.uk</a>
        <h3>10 tips to beat insomnia</h3>
        <a target="_blank" href='https://www.nhs.uk/live-well/sleep-and-tiredness/10-tips-to-beat-insomnia/'>https://www.nhs.uk</a>`,

        contentId: "bed",
        risk: -1,
        sanity: -1,
        image: "scenes/bedroom/Bed.png",
        style: {
          bottom: 0,
          right: 0,
        },
      },
      {
        title: "Yoga Mat",
        contentId: "yogamatt",
        risk: -1,
        sanity: 1,
        image: "scenes/bedroom/Yoga_matt.png",
        style: {
          bottom: 0,
          right: 67,
        },
      },
      {
        title: "Baby mat",
        contentId: "playmat",
        risk: -1,
        sanity: -1,
        image: "scenes/bedroom/Baby_Matt.png",
        style: {
          top: 360,
          left: 157,
        },
      },
      {
        title: "Picture",
        contentId: "familyportrait",
        risk: -1,
        sanity: 1,
        image: "scenes/bedroom/picture.png",
        style: {
          top: 0,
          right: 60,
        },
      },
      {
        title: "Diary",
        contentId: "diary",
        risk: -1,
        sanity: -1,
        image: "scenes/bedroom/Diary.png",
        style: {
          top: 240,
          right: 347,
        },
      },
    ],
  },
  kitchen: {
    backgroundUrl: "scenes/kitchen_background.jpg",
    left: "lounge",
    right: "garden",
    goesTo: "outside",
    items: [
      {
        title: "Sink",
        contentId: "sink",
        risk: -1,
        sanity: -1,
        image: "scenes/kitchen/Sink.png",
        style: {
          bottom: 0,
          left: 0,
        },
      },
      {
        title: "Oven",
        contentId: "oven",

        risk: -1,
        sanity: 1,
        image: "scenes/kitchen/Oven.png",
        style: {
          bottom: 198,
          left: 312,
        },
      },
      {
        title: "Easter egg",
        contentId: "egg",
        risk: -1,
        sanity: 1,
        image: "scenes/kitchen/Easter_egg.png",
        style: {
          top: 208,
          left: 172,
        },
      },
      {
        title: "Birthday card",
        contentId: "birthdaycard",

        risk: -1,
        sanity: 1,
        image: "scenes/kitchen/Birthday_card.png",
        style: {
          top: 198,
          right: 222,
        },
      },
      {
        title: "Fridge",
        contentId: "fridge",
        risk: -1,
        sanity: 1,
        image: "scenes/kitchen/Fridge.png",
        style: {
          bottom: 10,
          right: 0,
        },
      },
    ],
  },
  bathroom: {
    backgroundUrl: "scenes/bathroom_background.jpg",
    right: "bedroom",
    items: [
      {
        title: "Loo roll",
        contentId: "looroll",
        risk: -1,
        sanity: 1,
        image: "scenes/bathroom/Loo_roll.png",
        style: {
          bottom: 180,
          left: 470,
        },
      },
      {
        title: "Mirror",
        contentId: "mirror",
        risk: -1,
        sanity: -1,
        image: "scenes/bathroom/Mirror.png",

        style: {
          top: 0,
          left: 0,
        },
      },
      {
        title: "Plant",
        contentId: "plants",
        risk: -1,
        sanity: 1,
        image: "scenes/bathroom/Plant.png",
        style: {
          bottom: 0,
          right: 0,
        },
      },
      {
        title: "Scissors",
        contentId: "scissors",

        risk: -1,
        sanity: -1,
        image: "scenes/bathroom/Scissors.png",
        style: {
          bottom: 160,
          right: 290,
        },
      },
    ],
  },
  garden: {
    backgroundUrl: "scenes/garden_background.jpg",
    left: "kitchen",
    up: "outside_house",
    items: [
      {
        title: "Garden Chair",
        contentId: "gardenchair",
        risk: 1,
        sanity: 1,
        image: "scenes/garden/Chair_blue.png",
        style: {
          bottom: 40,
          right: 60,
        },
      },
      {
        title: "Fork",
        contentId: "gardenfork",
        risk: -1,
        sanity: 1,
        image: "scenes/garden/Fork.png",
        style: {
          bottom: 70,
          right: 480,
        },
      },
      {
        title: "Playhouse",
        contentId: "kidsplayhouse",
        risk: 1,
        sanity: 1,
        image: "scenes/garden/Playhouse.png",
        style: {
          top: 90,
          left: 200,
        },
      },
    ],
  },
  outside_house: {
    backgroundUrl: "scenes/outside_house_background.jpg",
    left: "community_garden",
    goesTo: "inside",
    up: "garden",
    items: [
      {
        title: "Car",
        contentId: "car",
        links: `<h3>Cummings Press Conference</h3>
        <a target="_blank" href='https://www.youtube.com/watch?v=-mSyZGy8LX8/'>https://www.youtube.com</a>`,

        risk: 1,
        sanity: -1,
        image: "scenes/outside_house/Car.png",
        style: {
          bottom: 30,
          right: 0,
        },
      },
      {
        title: "Man at window",
        links: `<h3>What is mutual aid?</h3>
        <a target="_blank" href='http://bigdoorbrigade.com/what-is-mutual-aid/?fbclid=IwAR1dmGJ7e-PLWvsyW2cVMoWyRu60T5P1HOqCgSnLJ-8Cd8OXaBmy3TWUH3s'>http://bigdoorbrigade.com</a>`,

        contentId: "someoneelse_swindow",
        risk: 1,
        sanity: 1,
        image: "scenes/outside_house/Man_at_window.png",
        style: {
          top: 25,
          right: 375,
        },
      },
      {
        title: "Man",
        contentId: "man",
        risk: 1,
        sanity: -1,
        image: "scenes/outside_house/Man.png",
        style: {
          bottom: 25,
          right: 375,
        },
      },
      {
        title: "Red door",
        contentId: "door",
        risk: 1,
        sanity: 1,
        image: "scenes/outside_house/Red_door.png",

        style: {
          bottom: 223,
          left: 150,
        },
      },
    ],
  },

  community_garden: {
    backgroundUrl: "scenes/community_garden_background.jpg",
    right: "outside_house",
    left: "park",
    items: [
      {
        title: "Veg box",
        contentId: "growingboxes",
        risk: 1,
        sanity: 1,
        links: `<h3>Hackney Volunteering</h3>
        <a target="_blank" href='https://www.vchackney.org/how-to-get-involved-and-support-your-community-during-this-emergency/'>https://www.vchackney.org</a>`,

        image: "scenes/community_garden/veg_box.png",
        style: {
          top: 280,
          left: 420,
        },
      },
      {
        title: "BLM",
        contentId: "placards",
        risk: 1,
        sanity: 1,
        links: `<h3>Focus E15</h3>
        <a target="_blank" href='https://focuse15.org/'>https://focuse15.org/</a>
        <h3>London Renter's Union</h3>
        <a target="_blank" href='https://londonrentersunion.org/'>https://londonrentersunion.org/</a>
        <h3>George Floyd</h3>
        <a target="_blank" href='https://edition.cnn.com/2020/05/29/us/george-floyd-new-video-officers-kneel-trnd/index.html'>https://www.cnn.com</a>
        <h3>Hackney BLM</h3>
        <a target="_blank" href='https://www.hackneycitizen.co.uk/2020/06/19/museum-home-public-consultation-future-robert-geffrye-statue/'>https://www.hackneycitizen.co.uk/</a>`,

        image: "scenes/community_garden/BLM_sign.png",
        style: {
          top: 40,
          right: 20,
        },
      },
    ],
  },

  park: {
    backgroundUrl: "scenes/park_background.jpg",
    right: "community_garden",
    left: "shop",
    items: [
      {
        title: "Slide",
        contentId: "kidsplayground",
        risk: 1,
        sanity: 1,
        image: "scenes/park/Barrier_and_slide.png",
        style: {
          top: 0,
          right: 0,
        },
      },
      {
        title: "Park sign",
        contentId: "parksign",
        risk: 1,
        sanity: 1,
        image: "scenes/park/Park_sign.png",
        style: {
          bottom: 80,
          left: 0,
        },
      },
    ],
  },

  shop: {
    backgroundUrl: "scenes/shop_background.jpg",
    right: "park",
    left: "vista",
    items: [
      {
        title: "Sign",
        contentId: "2metersign",
        risk: 1,
        sanity: -1,
        image: "scenes/shop/2m_sign.png",

        style: {
          bottom: 0,
          left: 40,
        },
      },
      {
        title: "Queue",
        contentId: "queue",
        risk: 1,
        sanity: -1,
        image: "scenes/shop/Queue.png",
        style: {
          bottom: 0,
          right: 306,
        },
      },
    ],
  },

  vista: {
    backgroundUrl: "scenes/vista_background.jpg",
    right: "shop",
    items: [
      {
        title: "A-Z Map",
        contentId: "azmap",
        image: "scenes/vista/A-Z_Map.png",
        style: {
          bottom: 100,
          left: 250,
        },
      },
      {
        title: "Bike",
        contentId: "bike",
        risk: 1,
        sanity: 1,
        image: "scenes/vista/Bike.png",
        style: {
          bottom: 150,
          left: 20,
        },
      },
      {
        title: "Curb",
        contentId: "streetsign",

        risk: 1,
        sanity: 1,
        image: "scenes/vista/road_curb.png",
        style: {
          bottom: 0,
          left: 100,
        },
      },

      {
        title: "Pedestrian Crossing",
        contentId: "crossing",
        risk: 1,
        sanity: -1,
        image: "scenes/vista/Pedestrian_crossing.png",
        style: {
          bottom: 0,
          right: 100,
        },
      },
    ],
  },
}

export default sceneList
