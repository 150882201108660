import React from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"
import ObjectContents from "../Content/ObjectContents"
import { toUrl } from "../utils"
import redPaper from "Assets/paper/red_paper.png"
import cornerPaper from "Assets/paper/corner_paper.png"
import paperBackground from "Assets/paper/paper_background.png"
import back from "Assets/paper/back.png"
import Links from "Content/Links"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: 1000,
    height: 690,
    marginRight: "-50%",
    marginTop: 20,
    transform: "translate(-50%, -50%)",
    backgroundRepeat: "no-repeat",
    background: toUrl(paperBackground),
    backgroundSize: "contain",
    border: "none",
    overflow: "visible",
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.0)",
  },
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root")

export default function Item({ item, onClick, isOpen, onOpen, onClose }) {
  const [modalIsOpen, setIsOpen] = React.useState(isOpen)
  function openModal() {
    onClick && onClick()
    setIsOpen(true)
    onOpen()
  }

  function closeModal() {
    setIsOpen(false)
    onClose()
  }

  const isInert = !item.contentId

  const links = item.links ? <Links text={item.links} /> : <></>

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        // className="s"
        style={customStyles}
        contentLabel="Example Modal"
      >
        <>
          <div
            className="zoomer"
            // style={{ backgroundImage: toUrl(item.image) }}
          >
            <div
              onClick={closeModal}
              className="item-title-container"
              style={{
                backgroundImage: toUrl(cornerPaper),
              }}
            >
              <h2>{item.title}</h2>
            </div>
            {links}
            <ObjectContents contentId={item.contentId} />
          </div>

          <div
            onClick={closeModal}
            className="backButtonContainer"
            style={{
              backgroundImage: toUrl(redPaper),
            }}
          >
            <img className="backButton" src={back} />
          </div>
        </>
      </Modal>

      <img
        src={item.image}
        onClick={isInert ? {} : openModal}
        className={`item ${isInert && "inert"}`}
        style={item.style}
      />
    </>
  )
}
