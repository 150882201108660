
import { toUrl } from "../utils"
import rainbow1 from 'Assets/rainbow/rainbow1.png'
import rainbow2 from 'Assets/rainbow/rainbow2.png'
import rainbow3 from 'Assets/rainbow/rainbow3.png'
import rainbow4 from 'Assets/rainbow/rainbow4.png'
import rainbow5 from 'Assets/rainbow/rainbow5.png'

const rainbows = [rainbow1, rainbow2, rainbow3, rainbow4, rainbow5]

const Rainbow = ({value, color}) => {

    return <img className="rainbow" src={rainbows[value || 0]} />
}
export default Rainbow