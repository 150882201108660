import React, { useState } from "react"
import YouTube from "react-youtube"
import { toUrl } from "../utils"
import flatscreen from "Assets/flatscreen.png"
import remote from "Assets/remote.png"
import start from "Assets/misc/Start_pandemic.png"
import Popup from "ui/Popup"
import redPaper from "Assets/paper/red_paper.png"

const Welcome = ({ enter }) => {
  const [startVideo, setStartVideo] = useState(false)
  const [target, setTarget] = useState(true)

  const onReady = (event) => {
    // debugger
    setTarget(event.target)
    // event.target.playVideo()
    //    setPlayVideo(event.target.playVideo)
  }

  const play = () => {
    setStartVideo(true)
    try {
      target.playVideo()
    } catch {}
  }

  const youtubeOptions = {
    playerVars: {
      modestbranding: 1,
      controls: 0,
      rel: 0,
      autoplay: 0,
    },
    height: "430px",
    width: "800px",
  }

  return (
    <div className="welcome">
      <div className="flatscreen">
        <img src={flatscreen} />
        <YouTube
          videoId="80RF4GLipCg"
          opts={youtubeOptions}
          onReady={onReady}
        />
        <div
          style={{
            width: 800,
            height: 434,
            backgroundColor: "#000",
            marginTop: -430,
            zIndex: 300,
            opacity: startVideo ? 0 : 100,
          }}
        />
        {/* <iframe
          width="800px"
          height="430px "
          src="https://www.youtube.com/embed/LlJIwTd9fqI?modestbranding=1&controls=0&rel=0&autoplay=0"
          title="YouTube video player"
          frameborder="0"
          allow="modestbranding; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> */}
      </div>
      <div
        className="welcome-button "
        style={{
          visibility: startVideo ? "initial" : "hidden",
          backgroundImage: toUrl(redPaper),
          // height: "120px",
          // width: "350px"
        }}
        // className=""
      
        onClick={enter}
      >
        <img style={{width: "300px"}} src={start}></img>
      </div>
      <img
        className="remote"
        style={{
          visibility: startVideo ? "hidden" : "initial",
          cursor: "pointer",
        }}
        onClick={play}
        src={remote}
      />
    </div>
  )
}

export default Welcome
